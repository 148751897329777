import { useState, useEffect } from 'react';
import { getBoundary } from 'vibemap-constants/dist/helpers.js'


const useFetchBoundary = (
    showBoundary,
    cityCurrent,
    cache_bust = true,
    setBoundariesCallback = () => { }
) => {
    const [boundaries, setBoundaries] = useState(null);
    const [geoJSON, setGeoJSON] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (showBoundary && cityCurrent && (boundaries == null || boundaries.length === 0)) {
                const fetchBoundary = async () => {
                try {
                    const boundaryObject = await getBoundary(cityCurrent?.slug, cache_bust);
                    const boundaries = boundaryObject?.the_geom;
                    setBoundaries(boundaries);
                    setBoundariesCallback(boundaries);
                    console.log('DEBUG boundaryObject ', boundaryObject, boundaries);
                    const polygonsGeoJSON = boundaryObject?.polygons;
                    if (polygonsGeoJSON && polygonsGeoJSON != []) {
                        setGeoJSON(polygonsGeoJSON);
                    }
                } catch (error) {
                    console.error('Failed to fetch boundary:', error);
                    setError(error);
                }
            };

            fetchBoundary();
        }
    }, [showBoundary, cityCurrent, boundaries]);

    return {
        boundaries,
        geoJSON,
        error
    }
}

export default useFetchBoundary;