import React, { useState } from 'react'
import { pure } from 'recompose'
import { graphql } from 'gatsby'

import { pageStore } from "state/store-zustand";

import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'

import Header from "components/header"

import AddForm from 'components/forms/addEventFormBasic'
import VibemapSearchProvider from 'components/utils/search-context/Provider'
import VibemapSearchField from 'components/search/Field'
import VibemapSearchParameterPersister from 'components/search/ParameterPersister'

import '../styles/search.scss'
import 'components/forms/form.scss'

function AddEvent({ data, location, ...props }) {

  // Page context and options
  const pageLoader = usePageLoading()
  //const [embedded, setEmbedded] = useState(false)
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const embedded = pageStore((state) => state.embedded)

  // Form content and state
  const showIntroMessage = theme?.eventForm?.showIntroMessage
  const [title, setTitle] = useState(theme ? theme?.eventForm?.title : `Add Event`)
  const [message, setMessage] = useState(null)
  // (<>Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href={`mailto:info@vibemap.com`}>info@vibemap.com</a></>
  const [event, setEvent] = useState(null)
  const [clearForm, setClearForm] = useState(false)

  const [from, setFrom] = useState(location?.state ? location.state.from : ``)
  console.log(`TODO: pass from slug to form for redirect? `, from)

  const handleClick = (event, data) => {
    //console.log('handleClick ', event, data)
    if (data.details) {
      setEvent(data.details)
    }
  }

  const handleSubmit = (event, success = false) => {
    console.log('TODO: handleSubmit ', event);
    setEvent(null)
    setClearForm(true)
    setTitle('Event successfully added - Thanks!')
    setMessage(<p>Event successfully added! Send any questions to  Send any questions or suggestions. <a href={`mailto:info@vibemap.com`} style={{ color: 'white'}}>info@vibemap.com</a></p>)
    setClearForm(true)
  }

  // TDOO: memoize addFOrm
  const AddFormMemo = React.memo(AddForm);

  // TODO: use zustand and reduce 1 render
  const [isClient, setIsClient] = useState(false)
  React.useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return (
    <>
      <main className={`page add-event ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>
        <Header />
        <div className='container'>
          <div className='text has-bottom-padding'>
            {showIntroMessage
              ? <>
                  <h2>{title}</h2>
                  <p>{message}</p>
                </>
              : null
            }
          </div>

          <VibemapSearchProvider
            doAutoSearch
            //storedCities={data.allWpCity.nodes}
            preferStoredCities
            showGuides={false} >
            <div className='container'>
              <fieldset>
                {false &&
                  <>
                    <VibemapSearchParameterPersister />
                    <VibemapSearchField
                      autoFocus={false}
                      autoComplete
                      onClick={handleClick}
                      placeholder={`Type place name or address`}
                      shouldClearForm={clearForm}
                      showCities={false}
                      showGuides={false}
                      searchGeocoder />
                  </>
                }
              </fieldset>
              <AddFormMemo
                event={event}
                onSubmitCallback={handleSubmit}
                clearForm={clearForm}
                message={message}
                title={title}
                />
            </div>
          </VibemapSearchProvider>
        </div>
      </main>
    </>
  )
}

export const pageQuery = graphql`
query {
  allWpCity {
    nodes {
      title
      link
      uri
      cityDetails {
        description
        icon {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`
AddEvent.whyDidYouRender = true
export default pure(AddEvent);